import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";
import networkErrorHandler from "../../utils/networkErrorHandler";
import { User } from "./authSlice";

export interface Executive {
  id: string;
  name?: string;
  profilePicture?: string;
  role?: string;
  engagementType?: string;
  experience?: string;
  startDate?: string;
  linkedIn?: string;
  cultureLink?: string;
  biography?: string;
}

type ExecutiveAddRequest = Omit<Executive, "id" | "cultureLink">;

export interface Company {
  id?: string;
  name?: string;
  location?: string;
  logoLink?: string;
  foundedYear?: string;
  companySize?: string;
  fundsRaised?: string;
  lastFundraise?: string;
  cultureStatements?: string;
  linkedIn?: string;
  websiteLink?: string;
  aboutUs?: string;
  missionStatement?: string;
  executiveTeam: Executive[];
}

export type CompanyAddRequest = Omit<Company, "id" | "executiveTeam">;

export interface TeamMember {
  id: string;
  name?: string;
  emails: string[];
  profilePictureLink?: string;
  onboarded?: boolean;
}

export interface CompanyClaimAvailable {
  name?: string;
  logoLink?: string;
  available: boolean;
}

export const getCompanyProfile = createAsyncThunk(
  "companyProfile/get",
  async (
    {
      id,
      signal,
    }: {
      id: string;
      signal: AbortSignal;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Company } = await axios.get(
        `/api/company/${id}`,
        {
          signal,
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const updateCompanyProfile = createAsyncThunk(
  "companyProfile/update",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: CompanyAddRequest | FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Partial<Company> } = await axios.patch(
        `/api/company/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const addExecutive = createAsyncThunk(
  "companyProfile/update",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: ExecutiveAddRequest | FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Partial<Company> } = await axios.post(
        `/api/company/${id}/executive`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const updateExecutive = createAsyncThunk(
  "companyProfile/update",
  async (
    {
      companyId,
      executiveId,
      data,
      token,
    }: {
      companyId: string;
      executiveId: string;
      data: ExecutiveAddRequest | FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Partial<Company> } = await axios.patch(
        `/api/company/${companyId}/executive/${executiveId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const reorderExecutives = createAsyncThunk(
  "companyProfile/update",
  async (
    {
      companyId,
      data,
      token,
    }: {
      companyId: string;
      data: { orderedExecutiveIds: string[] };
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Partial<Company> } = await axios.post(
        `/api/company/${companyId}/executive/reorder`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const deleteExecutive = createAsyncThunk(
  "companyProfile/update",
  async (
    {
      companyId,
      executiveId,
      token,
    }: {
      companyId: string;
      executiveId: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: Partial<Company> } = await axios.delete(
        `/api/company/${companyId}/executive/${executiveId}`,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const getTeam = createAsyncThunk(
  "team/get",
  async (
    {
      id,
      token,
      signal,
    }: {
      id: string;
      token: string;
      signal: AbortSignal;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: TeamMember[] } = await axios.get(
        `/api/company/${id}/team`,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
          signal,
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const addUserToTeam = createAsyncThunk(
  "team/post",
  async (
    {
      companyId,
      data,
      token,
    }: {
      companyId: string;
      data: {
        email: string;
        name: string;
      };
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: TeamMember } = await axios.post(
        `/api/company/${companyId}/team/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const removeUserFromTeam = createAsyncThunk(
  "team/delete",
  async (
    {
      companyId,
      userId,
      token,
    }: {
      companyId: string;
      userId: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      await axios.delete(`/api/company/${companyId}/team/${userId}`, {
        headers: {
          Authorization: `Bearer ${token},`,
        },
      });

      return userId;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const getCompanyClaimStatus = createAsyncThunk(
  "claim/get",
  async (
    {
      id,
      signal,
    }: {
      id: string;
      signal: AbortSignal;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: CompanyClaimAvailable } = await axios.get(
        `/api/company/${id}/available`,
        {
          signal,
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const claimCompanyProfile = createAsyncThunk(
  "claim/post",
  async (
    {
      id,
      token,
    }: {
      id: string;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: User } = await axios.post(
        `/api/company/${id}/claim`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

const sortTeam = (a: TeamMember, b: TeamMember): number => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  } else if (!a.name && !b.name) {
    return 0;
  } else if (!a.name) {
    return -1;
  } else {
    return 1;
  }
};

// Define a type for the slice state
interface CompanyState {
  company: Company;
  team: TeamMember[];
  companyClaim?: CompanyClaimAvailable;
  status: "idle" | "pending" | "succeeded" | "failed";
  postingStatus: "idle" | "posting" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: CompanyState = {
  company: {
    executiveTeam: [],
  },
  team: [],
  status: "idle",
  postingStatus: "idle",
};

export const companyProfileSlice = createSlice({
  name: "companyProfile",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetPostingStatus: (state) => {
      state.postingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyProfile.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getCompanyProfile.fulfilled,
        (state, action: PayloadAction<Company | undefined>) => {
          state.company = {
            executiveTeam: [],
            ...action.payload,
          };
          state.status = "succeeded";
        },
      )
      .addCase(getCompanyProfile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateCompanyProfile.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        updateCompanyProfile.fulfilled,
        (state, action: PayloadAction<Partial<Company> | undefined>) => {
          state.company = {
            ...state.company,
            ...action.payload,
          };
          state.postingStatus = "succeeded";
        },
      )
      .addCase(updateCompanyProfile.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(getTeam.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getTeam.fulfilled,
        (state, action: PayloadAction<TeamMember[] | undefined>) => {
          if (action.payload) {
            state.team = [...action.payload].sort(sortTeam);
          } else {
            state.team = [];
          }
          state.status = "succeeded";
        },
      )
      .addCase(getTeam.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addUserToTeam.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        addUserToTeam.fulfilled,
        (state, action: PayloadAction<TeamMember | undefined>) => {
          if (action.payload) {
            state.team = [...state.team, action.payload].sort(sortTeam);
          }
          state.postingStatus = "succeeded";
        },
      )
      .addCase(addUserToTeam.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(removeUserFromTeam.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        removeUserFromTeam.fulfilled,
        (state, action: PayloadAction<string | undefined>) => {
          state.team = state.team.filter(({ id }) => id !== action.payload);
          state.postingStatus = "succeeded";
        },
      )
      .addCase(removeUserFromTeam.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(getCompanyClaimStatus.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getCompanyClaimStatus.fulfilled,
        (state, action: PayloadAction<CompanyClaimAvailable | undefined>) => {
          state.companyClaim = action.payload;
          state.status = "succeeded";
        },
      )
      .addCase(getCompanyClaimStatus.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(claimCompanyProfile.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(claimCompanyProfile.fulfilled, (state) => {
        state.postingStatus = "succeeded";
      })
      .addCase(claimCompanyProfile.rejected, (state) => {
        state.postingStatus = "failed";
      });
  },
});

export const { resetPostingStatus } = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
