import React, { SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import ReactDatePicker from "react-datepicker";
import {
  applyForOpening,
  resetPostingStatus,
  withdrawApplicationForOpening,
} from "../redux/features/candidateOpeningSlice";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  Field,
  InfoLabel,
  Input,
  mergeClasses,
  Radio,
  RadioGroup,
  Skeleton,
  SkeletonItem,
  Spinner,
  Textarea,
} from "@fluentui/react-components";
import { CheckmarkCircleRegular } from "@fluentui/react-icons";
import { useDialogStyles, useFieldStyles } from "../fluentStyles";
import { Button as InternalButton } from "../elements/Button";
import {
  CalendarSVG,
  CloseCircleSVG,
  DocumentTextSVG,
  DollarSquareSVG,
  EllipseSVG,
  LinkedInSVG,
  MoneyReceiveSVG,
  MonitorMobileSVG,
  PeopleSVG,
  Profile2UserSVG,
  ReceiptEditSVG,
  TaskSquareSVG,
} from "../assets/svgs";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";
import {
  CandidateAcceptOpportunityData,
  CandidateDeclineOpportunityData,
  CandidateDeclineReason,
  candidateDeclineReasonList,
  CandidateJobOpening,
  CompanyJobOpening,
  JobOpeningStatus,
  SvgJsxType,
} from "../types";
import localDateToDateString from "../utils/localDateToDateString";

const COMPANY_STAGES = [
  "Collecting Data",
  "Finding Candidates",
  "Interviews & Selection",
] as const;

const StatusToCompanyStageIndex: Record<JobOpeningStatus, 0 | 1 | 2> = {
  "New Opening": 0,
  "Creating Job Spec": 0,
  "Identifying Candidates": 1,
  "Presenting Shortlist": 2,
  "Role Filled (won)": 2,
  "Role Filled (lost)": 2,
};

const MAX_QUOTE_CHARACTERS = 300;

export function NewCandidateOpportunityTile({
  opening,
}: {
  opening?: CandidateJobOpening;
}): JSX.Element {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [applyDialogOpen, setApplyDialogOpen] = useState(false);

  const postingStatus = useAppSelector(
    (state) => state.candidateOpening.postingStatus,
  );

  const dispatch = useAppDispatch();

  const dialogStyle = useDialogStyles();

  const openingId = opening?.id;
  const candidateStatusId = opening?.candidateStatus.id;

  const actionsDisabled =
    postingStatus === "posting" || !openingId || !candidateStatusId;

  useEffect(() => {
    if (postingStatus === "succeeded") {
      setDetailsDialogOpen(false);
      dispatch(resetPostingStatus());
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  const apply = () => {
    setDetailsDialogOpen(false);
    setApplyDialogOpen(true);
  };

  if (!opening) {
    return (
      <div className="card flex-col gap-4 w-full">
        <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
          <div className="flex-1 flex flex-col gap-1">
            <Skeleton className="w-40 h-8">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
        <div className="flex gap-6 self-stretch">
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
        </div>
        <Skeleton className="w-full h-8">
          <SkeletonItem size={32} className="w-full" />
        </Skeleton>
      </div>
    );
  }

  let stages: string[] = [];
  let currentStage = 0;
  let allowApplication = false;
  let allowWithdrawal = false;

  switch (opening.candidateStatus.status) {
    case "Identified":
      // Do nothing, this should not have been sent by the API
      break;
    // If the candidate is being considered
    case "Checking Interest":
      stages = [
        "Invited to Apply",
        "Awaiting Company Review",
        "Interviewing",
        "Awaiting Company Decision",
      ];
      currentStage = 0;
      allowApplication = true;
      break;
    // If the candidate has opted in and is being evaluated by Venturous
    case "Screening":
    case "Possible Fit":
    case "Shortlisted":
      // If an interview process is defined
      if (
        opening.interviews?.some(({ interviewRequested }) => interviewRequested)
      ) {
        // Identify how many rounds of interviews there are
        // How many the candidate has been invited to
        // And how many they have completed
        const rounds = opening.interviews.length;
        const invited = opening.interviews.reduce(
          (acc, { interviewRequested }) => (interviewRequested ? acc + 1 : acc),
          0,
        );
        const completed = opening.interviews.reduce(
          (acc, { interviewCompleted }) => (interviewCompleted ? acc + 1 : acc),
          0,
        );

        // If they've completed all the rounds
        if (completed === rounds) {
          stages = [
            "Applied",
            "Awaiting Company Review",
            "Interviewing: Completed",
            "Awaiting Company Decision",
          ];
          currentStage = 3;
        } else {
          // Otherwise, identify how far in the process they are
          stages = [
            "Applied",
            "Awaiting Company Review",
            `Interviewing: ${invited} of ${rounds}`,
            "Awaiting Company Decision",
          ];
          currentStage = 2;
        }
      } else {
        // If the interview process is not defined, be non-specific
        stages = [
          "Applied",
          "Awaiting Company Review",
          "Interviewing",
          "Awaiting Company Decision",
        ];
        currentStage = 1;
      }
      allowWithdrawal = true;
      break;
    // If they won the role
    case "Accepted by Client":
      stages = [
        "Applied",
        "Awaiting Company Review",
        "Interviewing",
        "Decision: Hired!",
      ];
      currentStage = 4;
      break;
    // If the role went to someone else
    case "Rejected for Role":
      stages = [
        "Applied",
        "Profile Reviewed",
        "Interviewed",
        "Decision: Hired Someone Else",
      ];
      currentStage = 4;
      break;
    // If they opted out
    case "Not Interested":
      stages = ["Invited to Apply", "Passed on Opportunity"];
      currentStage = 2;
      break;
    default:
    // No default
  }

  // If the status was "Identified" or something unexpected, return
  if (!stages.length) {
    return <></>;
  }

  return (
    <div className="card flex-col gap-4 w-full">
      <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
        <Avatar
          image={{ src: opening.company?.logoLink }}
          name={opening.company?.name}
          size={48}
        />
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-base font-semibold text-[#22234A]">
            {opening.role}
          </p>
          <p className="text-sm font-normal text-[#727E94]">
            {opening.company?.name}
          </p>
        </div>
        {allowApplication ? (
          <>
            <InternalButton
              type="primary"
              disabled={actionsDisabled}
              onClick={apply}
            >
              Apply Now
            </InternalButton>
            <DeclineOrWithdrawOpportunityDialog
              mode="decline"
              openingId={openingId}
              candidateStatusId={candidateStatusId}
              disabled={actionsDisabled}
            />
          </>
        ) : allowWithdrawal ? (
          <>
            <InternalButton
              type="primary"
              disabled={actionsDisabled}
              onClick={() =>
                window.open(
                  `/opening/${openingId}/application/${candidateStatusId}`,
                  "_blank",
                )
              }
            >
              Preview Application
            </InternalButton>
            <DeclineOrWithdrawOpportunityDialog
              mode="withdraw"
              openingId={openingId}
              candidateStatusId={candidateStatusId}
              disabled={actionsDisabled}
            />
          </>
        ) : null}
        <InternalButton
          type="secondary"
          disabled={actionsDisabled}
          onClick={() => setDetailsDialogOpen(true)}
        >
          See Details
        </InternalButton>
      </div>
      <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
        <OpportunityKeyPoint title="Scope" value={opening?.scope} />
        <OpportunityKeyPoint title="Pay" value={opening?.budget} />
        <OpportunityKeyPoint
          title="Desired Start Date"
          value={
            opening?.desiredStartDate &&
            dateStringToLocalDate(
              opening?.desiredStartDate,
            )?.toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })
          }
        />
      </div>
      <ProgressTracker
        opportunityId={opening?.id ?? ""}
        stages={stages}
        currentStage={currentStage}
      />
      <Dialog
        open={detailsDialogOpen}
        onOpenChange={(_evt, data) => setDetailsDialogOpen(data.open)}
      >
        <DialogSurface
          className={mergeClasses(
            dialogStyle.editDialog,
            dialogStyle.wideDialog,
          )}
        >
          <DialogBody>
            <DialogTitle>
              <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
                Opportunity Details
                <DialogTrigger disableButtonEnhancement>
                  <CloseCircleSVG
                    className="cursor-pointer"
                    height="20"
                    width="20"
                  />
                </DialogTrigger>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex gap-3 self-stretch items-center">
                <Avatar
                  image={{ src: opening.company?.logoLink }}
                  name={opening.company?.name}
                  size={48}
                />
                <div className="flex-1 flex flex-col gap-1">
                  <p className="text-base font-semibold text-[#22234A]">
                    {opening.role}
                  </p>
                  <p className="text-sm font-normal text-[#727E94]">
                    {opening.company?.name}
                  </p>
                </div>
                {allowApplication ? (
                  <>
                    <InternalButton
                      type="primary"
                      disabled={actionsDisabled}
                      onClick={apply}
                    >
                      Apply Now
                    </InternalButton>
                    <DeclineOrWithdrawOpportunityDialog
                      mode="decline"
                      openingId={openingId}
                      candidateStatusId={candidateStatusId}
                      disabled={actionsDisabled}
                    />
                  </>
                ) : allowWithdrawal ? (
                  <>
                    <InternalButton
                      type="primary"
                      disabled={actionsDisabled}
                      onClick={() =>
                        window.open(
                          `/opening/${openingId}/application/${candidateStatusId}`,
                          "_blank",
                        )
                      }
                    >
                      Preview Application
                    </InternalButton>
                    <DeclineOrWithdrawOpportunityDialog
                      mode="withdraw"
                      openingId={openingId}
                      candidateStatusId={candidateStatusId}
                      disabled={actionsDisabled}
                    />
                  </>
                ) : null}
              </div>
              <Divider />
              <ProgressTracker
                opportunityId={opening?.id ?? ""}
                stages={stages}
                currentStage={currentStage}
              />
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h2 className="text-xl font-semibold text-[#22234A]">
                  Job Details
                </h2>
                <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                  {opening.shortDescription}
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
                <OpportunityKeyPoint title="Scope" value={opening?.scope} />
                <OpportunityKeyPoint title="Pay" value={opening?.budget} />
                <OpportunityKeyPoint
                  title="Desired Start Date"
                  value={
                    opening?.desiredStartDate &&
                    dateStringToLocalDate(
                      opening?.desiredStartDate,
                    )?.toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  }
                />
              </div>
              <Divider />
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h2 className="text-xl font-semibold text-[#22234A]">
                  Key Responsibilities
                </h2>
                <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                  {opening.keyResponsibilities}
                </p>
              </div>
              <Divider />
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h2 className="text-xl font-semibold text-[#22234A]">
                  About Us
                </h2>
                <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                  {opening.company?.aboutUs}
                </p>
              </div>
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h3 className="text-base font-semibold text-[#22234A]">
                  Background
                </h3>
                <div className="p-4 flex flex-col gap-2 self-stretch border border-[#F2F2F2] rounded-xl">
                  <div className="flex flex-col self-stretch">
                    <BackgroundItem
                      icon={
                        <ReceiptEditSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Founded"
                      value={opening.company?.foundedYear}
                    />
                    <BackgroundItem
                      icon={
                        <PeopleSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Company Size"
                      value={opening.company?.companySize}
                    />
                    <BackgroundItem
                      icon={
                        <DollarSquareSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Funds Raised"
                      value={opening.company?.fundsRaised}
                    />
                    <BackgroundItem
                      icon={
                        <MoneyReceiveSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Last Fundraise"
                      value={opening.company?.lastFundraise}
                    />
                  </div>
                </div>
              </div>
              {
                /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
                opening.company?.linkedIn || opening.company?.websiteLink ? (
                  <div className="flex flex-col gap-2 self-stretch flex-wrap">
                    <h3 className="text-base font-semibold text-[#22234A]">
                      Company Links
                    </h3>
                    <div className="flex gap-2 self-stretch">
                      <LinkButton
                        icon={
                          <LinkedInSVG
                            className="flex-shrink-0"
                            height="20"
                            width="20"
                          />
                        }
                        text="Linkedin"
                        link={opening.company?.linkedIn}
                      />
                      <LinkButton
                        icon={
                          <MonitorMobileSVG
                            className="flex-shrink-0"
                            height="20"
                            width="20"
                          />
                        }
                        text="Website"
                        link={opening.company?.websiteLink}
                      />
                      <InternalButton
                        type="primary"
                        disabled={actionsDisabled}
                        onClick={() =>
                          window.open(
                            `/company/${opening.company?.id}`,
                            "_blank",
                          )
                        }
                      >
                        Company Profile
                      </InternalButton>
                    </div>
                  </div>
                ) : null
                /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
              }
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <ApplyDialog
        opening={opening}
        isOpen={applyDialogOpen}
        setIsOpen={setApplyDialogOpen}
      />
    </div>
  );
}

function BackgroundItem({
  icon,
  name,
  value,
}: {
  icon: JSX.Element;
  name: string;
  value?: string;
}) {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-3 self-stretch justify-between py-4 border-b border-[#F7F7F7] first:pt-0 last:pb-0 last:border-b-0">
      <div className="flex gap-3">
        {icon}
        <p className="text-sm font-medium text-[#22234A]">{name}</p>
      </div>
      <p className="text-sm font-medium text-[#22234A]">{value}</p>
    </div>
  );
}

function LinkButton({
  icon,
  text,
  link,
}: {
  icon: JSX.Element;
  text: string;
  link?: string;
}) {
  if (!link) {
    return;
  }

  return (
    <InternalButton
      type="secondary"
      onClick={() =>
        window.open(
          link.startsWith("http") ? link : `https://${link}`,
          "_blank",
        )
      }
    >
      {icon}
      {text}
    </InternalButton>
  );
}

function DeclineOrWithdrawOpportunityDialog({
  openingId,
  candidateStatusId,
  mode,
  disabled,
}: {
  openingId?: string;
  candidateStatusId?: string;
  mode: "decline" | "withdraw";
  disabled: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [declineReasons, setDeclineReasons] = useState<
    CandidateDeclineReason[]
  >([]);
  const [declineReasonOther, setDeclineReasonsOther] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.candidateOpening.postingStatus,
  );

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  function decline() {
    if (disabled || !openingId || !candidateStatusId) {
      return;
    }

    const data: CandidateDeclineOpportunityData = {
      declineReasons,
    };

    if (declineReasonOther) {
      data.otherDeclineReason = otherReason;
    }

    void (async () => {
      const token = await getAccessTokenSilently();

      void dispatch(
        withdrawApplicationForOpening({
          openingId,
          candidateStatusId,
          data,
          token,
        }),
      );
    })();
  }

  useEffect(() => {
    if (postingStatus === "succeeded") {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  useEffect(() => {
    if (!isOpen) {
      setDeclineReasons([]);
      setDeclineReasonsOther(false);
      setOtherReason("");
    }
  }, [isOpen]);

  return (
    <>
      <InternalButton
        disabled={disabled}
        type="secondary"
        onClick={() => setIsOpen(true)}
      >
        {mode === "decline" ? "Decline Opportunity" : "Withdraw Application"}
      </InternalButton>
      <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
        <DialogSurface className={dialogStyle.editDialog}>
          <DialogBody>
            <DialogTitle>
              <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
                {mode === "decline"
                  ? "Declining Opportunity"
                  : "Withdrawing Application"}
                <DialogTrigger disableButtonEnhancement>
                  <CloseCircleSVG
                    className="cursor-pointer"
                    height="20"
                    width="20"
                  />
                </DialogTrigger>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex flex-col gap-1 items-center self-stretch">
                <h2 className="text-lg font-semibold text-[#22234A]">
                  {mode === "decline"
                    ? "Why are you not interested in this opportunity?"
                    : "Why are you withdrawing your application?"}
                </h2>
                <p className="text-sm font-medium text-[#727E94]">
                  Select as many as apply
                </p>
              </div>
              <div className="flex flex-col gap-2 self-stretch">
                {candidateDeclineReasonList.map((reason) => (
                  <div
                    key={`decline-reason-${reason}`}
                    className="flex gap-1 items-center self-stretch"
                  >
                    <Checkbox
                      checked={declineReasons.includes(reason)}
                      onChange={(_evt, { checked }) => {
                        setDeclineReasons((prev) => {
                          let newReasons: CandidateDeclineReason[];
                          if (checked) {
                            newReasons = [...prev, reason];
                          } else {
                            newReasons = prev.filter((el) => el !== reason);
                          }

                          return newReasons;
                        });
                      }}
                      label={reason}
                    />
                  </div>
                ))}
                <div className="flex gap-1 items-center self-stretch">
                  <Checkbox
                    checked={declineReasonOther}
                    onChange={(_evt, { checked }) => {
                      setDeclineReasonsOther(Boolean(checked));

                      if (!checked) {
                        // Clear the text field if other is deselected
                        setOtherReason("");
                      }
                    }}
                    label="Other"
                  />
                </div>
              </div>
              {declineReasonOther ? (
                <div className="flex flex-col gap-2 self-stretch items-stretch">
                  <Field
                    label="Please specify"
                    className={fieldStyle.editField}
                  >
                    <Textarea
                      value={otherReason}
                      onChange={(_evt, { value }) => setOtherReason(value)}
                      resize="vertical"
                    />
                  </Field>
                </div>
              ) : null}
            </DialogContent>
            <DialogActions>
              <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
                <DialogTrigger disableButtonEnhancement>
                  <Button className="flex-1" appearance="secondary">
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button
                  className="flex-1"
                  appearance="primary"
                  disabled={disabled}
                  onClick={decline}
                >
                  Submit
                </Button>
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
}

function ApplyDialog({
  opening,
  isOpen,
  setIsOpen,
}: {
  opening: CandidateJobOpening;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [step, setStep] = useState<"fit" | "why" | "submitting" | "done">(
    "fit",
  );
  // Inelegant booleans to avoid needing to have a value set to begin with
  // while also not changing from uncontrolled to controlled
  const [agreesWithScope, setAgreesWithScope] = useState<"true" | "false" | "">(
    "",
  );
  const [agreesWithPay, setAgreesWithPay] = useState<"true" | "false" | "">("");
  const [agreesWithStartDate, setAgreesWithStartDate] = useState<
    "true" | "false" | ""
  >("");
  const [openToFullTime, setOpenToFullTime] = useState<"true" | "false" | "">(
    "",
  );
  const [scope, setScope] = useState("");
  const [budget, setBudget] = useState("");
  const [earliestStartDate, setEarliestStartDate] = useState("");
  const [matchCriteria1Quote, setMatchCriteria1Quote] = useState("");
  const [matchCriteria2Quote, setMatchCriteria2Quote] = useState("");
  const [matchCriteria3Quote, setMatchCriteria3Quote] = useState("");

  const postingStatus = useAppSelector(
    (state) => state.candidateOpening.postingStatus,
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const dialogStyle = useDialogStyles();
  const fieldStyle = useFieldStyles();

  const openingId = opening?.id;
  const candidateStatusId = opening?.candidateStatus.id;

  const submitDisabled =
    postingStatus === "posting" || !openingId || !candidateStatusId;

  const fitComplete =
    // Responded to scope question
    agreesWithScope &&
    // And agrees or set an alternative
    (agreesWithScope === "true" || scope) &&
    // Responded to pay question
    agreesWithPay &&
    // And agrees or set an alternative
    (agreesWithPay === "true" || budget) &&
    // Responded to start date question
    agreesWithStartDate &&
    // And agrees or set an alternative
    (agreesWithStartDate === "true" || earliestStartDate) &&
    // Role doesn't convert to full time or answered question
    (opening.convertsToFullTime !== "Yes" || openToFullTime);

  function submit() {
    if (submitDisabled) {
      return;
    }

    const data: CandidateAcceptOpportunityData = {
      agreesWithScope: agreesWithScope === "true",
      agreesWithPay: agreesWithPay === "true",
      agreesWithStartDate: agreesWithStartDate === "true",
      openToFullTime: openToFullTime === "true",
      matchCriteria1Quote,
      matchCriteria2Quote,
      matchCriteria3Quote,
    };

    if (!data.agreesWithScope) {
      data.scope = scope;
    }

    if (!data.agreesWithPay) {
      data.budget = budget;
    }

    if (!data.agreesWithStartDate) {
      data.earliestStartDate = earliestStartDate;
    }

    setStep("submitting");
    void (async () => {
      const token = await getAccessTokenSilently();

      void dispatch(
        applyForOpening({ openingId, candidateStatusId, data, token }),
      );
    })();
  }

  useEffect(() => {
    if (step === "submitting" && postingStatus === "succeeded") {
      setStep("done");
    }
  }, [postingStatus, step]);

  useEffect(() => {
    if (!isOpen) {
      setStep("fit");
      setAgreesWithScope("");
      setAgreesWithPay("");
      setAgreesWithStartDate("");
      setOpenToFullTime("");
      setScope("");
      setBudget("");
      setEarliestStartDate("");
      setMatchCriteria1Quote("");
      setMatchCriteria2Quote("");
      setMatchCriteria3Quote("");
    }
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} onOpenChange={(_evt, data) => setIsOpen(data.open)}>
        <DialogSurface
          className={mergeClasses(
            dialogStyle.editDialog,
            // dialogStyle.wideDialog
          )}
        >
          <DialogBody>
            <DialogTitle>
              <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
                {opening.company?.name
                  ? `Applying to ${opening.company?.name}`
                  : "Applying to Opportunity"}
                <DialogTrigger disableButtonEnhancement>
                  <CloseCircleSVG
                    className="cursor-pointer"
                    height="20"
                    width="20"
                  />
                </DialogTrigger>
              </div>
            </DialogTitle>
            <DialogContent>
              {step === "submitting" ? (
                <div className="flex-1 flex flex-col items-center justify-center gap-8">
                  <Spinner size="huge" />
                  <div className="flex flex-col gap-1 items-stretch text-center">
                    <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
                      Submitting your application
                    </p>
                    <p className="text-sm font-medium text-[#727E94]">
                      Just a few seconds left
                    </p>
                  </div>
                </div>
              ) : step === "done" ? (
                <div className="flex-1 flex flex-col items-center justify-center gap-8">
                  <CheckmarkCircleRegular className="h-[120px] w-[120px] text-[#1E7667]" />
                  <div className="flex flex-col gap-1 items-stretch text-center">
                    <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
                      You have successfully applied for this position
                    </p>
                    <p className="text-sm font-medium text-[#727E94]">
                      You can preview the application profile that will be
                      shared with the company below
                    </p>
                  </div>
                </div>
              ) : step === "fit" ? (
                <>
                  <div className="flex flex-col gap-1 self-stretch items-center">
                    <h2 className="text-lg font-semibold text-[#22234A]">
                      Confirm Opportunity Fit
                    </h2>
                    <p className="text-sm font-medium text-[#4E4F6C]">
                      Please confirm the proposed terms of the engagement
                    </p>
                  </div>
                  <div className="flex flex-col gap-2 self-stretch items-center">
                    <Field
                      label={`The proposed scope for this role is ${opening.scope}`}
                      className={mergeClasses(
                        fieldStyle.editField,
                        "w-full max-w-80",
                      )}
                    >
                      <RadioGroup
                        className="justify-between"
                        value={agreesWithScope}
                        onChange={(_, data) =>
                          setAgreesWithScope(data.value as "true" | "false")
                        }
                        layout="horizontal"
                      >
                        <Radio value="true" label="Yes, agree" />
                        <Radio value="false" label="Propose alternative" />
                      </RadioGroup>
                    </Field>
                  </div>
                  {agreesWithScope === "false" ? (
                    <div className="flex flex-col gap-2 self-stretch items-center">
                      <Field
                        label="Propose your alternative"
                        className={mergeClasses(
                          fieldStyle.editField,
                          "w-full max-w-80",
                        )}
                      >
                        <Input
                          type="text"
                          value={scope}
                          onChange={(_evt, { value }) => setScope(value)}
                        />
                      </Field>
                    </div>
                  ) : null}
                  <div className="flex flex-col gap-2 self-stretch items-center">
                    <Field
                      label={`The proposed pay for this role is ${opening.budget}`}
                      className={mergeClasses(
                        fieldStyle.editField,
                        "w-full max-w-80",
                      )}
                    >
                      <RadioGroup
                        className="justify-between"
                        value={agreesWithPay}
                        onChange={(_, data) =>
                          setAgreesWithPay(data.value as "true" | "false")
                        }
                        layout="horizontal"
                      >
                        <Radio value="true" label="Yes, agree" />
                        <Radio value="false" label="Propose alternative" />
                      </RadioGroup>
                    </Field>
                  </div>
                  {agreesWithPay === "false" ? (
                    <div className="flex flex-col gap-2 self-stretch items-center">
                      <Field
                        label="Propose your alternative"
                        className={mergeClasses(
                          fieldStyle.editField,
                          "w-full max-w-80",
                        )}
                      >
                        <Input
                          type="text"
                          value={budget}
                          onChange={(_evt, { value }) => setBudget(value)}
                        />
                      </Field>
                    </div>
                  ) : null}
                  <div className="flex flex-col gap-2 self-stretch items-center">
                    <Field
                      label={`Are you available to start on ${
                        opening.desiredStartDate
                          ? dateStringToLocalDate(
                              opening.desiredStartDate,
                            )?.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })
                          : "Unknown"
                      }?`}
                      className={mergeClasses(
                        fieldStyle.editField,
                        "w-full max-w-80",
                      )}
                    >
                      <RadioGroup
                        className="justify-between"
                        value={agreesWithStartDate}
                        onChange={(_, data) =>
                          setAgreesWithStartDate(data.value as "true" | "false")
                        }
                        layout="horizontal"
                      >
                        <Radio value="true" label="Yes, I am available" />
                        <Radio value="false" label="Propose alternative" />
                      </RadioGroup>
                    </Field>
                  </div>
                  {agreesWithStartDate === "false" ? (
                    <div className="flex flex-col gap-2 self-stretch items-center">
                      <Field
                        label="Propose your alternative"
                        className={mergeClasses(
                          fieldStyle.editField,
                          "w-full max-w-80",
                        )}
                      >
                        <ReactDatePicker
                          className="w-full flex-1 py-3 px-2 border border-[#E8E8E8] rounded-xl"
                          selected={
                            earliestStartDate
                              ? dateStringToLocalDate(earliestStartDate)
                              : null
                          }
                          onChange={(date) =>
                            setEarliestStartDate(
                              date ? localDateToDateString(date) : "",
                            )
                          }
                          dateFormat="MM/dd/yyyy"
                          showIcon
                          icon={<CalendarSVG className="top-[2px]" />}
                        />
                      </Field>
                    </div>
                  ) : null}
                  {opening.convertsToFullTime === "Yes" ? (
                    <div className="flex flex-col gap-2 self-stretch items-center">
                      <Field
                        label="Are you open to transitioning to a full-time role in the future?"
                        className={mergeClasses(
                          fieldStyle.editField,
                          "w-full max-w-80",
                        )}
                      >
                        <RadioGroup
                          className="justify-between"
                          value={openToFullTime}
                          onChange={(_, data) =>
                            setOpenToFullTime(data.value as "true" | "false")
                          }
                          layout="horizontal"
                        >
                          <Radio
                            value="true"
                            label="Yes, I open to full time"
                          />
                          <Radio value="false" label="No" />
                        </RadioGroup>
                      </Field>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-1 self-stretch items-center">
                    <h2 className="text-lg font-semibold text-[#22234A]">
                      Why You&apos;re a Great Match
                    </h2>
                    <p className="text-sm font-medium text-[#4E4F6C]">
                      Please confirm the information that Venturous matched you
                      based on below, then add context in your own words.
                    </p>
                  </div>
                  <MatchCriteriaTile
                    Icon={TaskSquareSVG}
                    criteria={opening.matchCriteria1}
                    response={opening.candidateStatus.matchCriteria1}
                    quote={matchCriteria1Quote}
                    setQuote={setMatchCriteria1Quote}
                  />
                  <MatchCriteriaTile
                    Icon={Profile2UserSVG}
                    criteria={opening.matchCriteria2}
                    response={opening.candidateStatus.matchCriteria2}
                    quote={matchCriteria2Quote}
                    setQuote={setMatchCriteria2Quote}
                  />
                  <MatchCriteriaTile
                    Icon={DocumentTextSVG}
                    criteria={opening.matchCriteria3}
                    response={opening.candidateStatus.matchCriteria3}
                    quote={matchCriteria3Quote}
                    setQuote={setMatchCriteria3Quote}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
                {step === "done" || step === "submitting" ? (
                  <DialogTrigger disableButtonEnhancement>
                    <Button className="flex-1" appearance="secondary">
                      Close
                    </Button>
                  </DialogTrigger>
                ) : (
                  <DialogTrigger disableButtonEnhancement>
                    <Button className="flex-1" appearance="secondary">
                      Cancel
                    </Button>
                  </DialogTrigger>
                )}
                {step === "done" ? (
                  <Button
                    className="flex-1"
                    appearance="primary"
                    onClick={() =>
                      navigate(
                        `/opening/${openingId}/application/${candidateStatusId}`,
                      )
                    }
                  >
                    Preview
                  </Button>
                ) : step === "fit" ? (
                  <Button
                    className="flex-1"
                    appearance="primary"
                    disabled={!fitComplete}
                    onClick={() => setStep("why")}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    className="flex-1"
                    appearance="primary"
                    disabled={submitDisabled}
                    onClick={submit}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
}

function MatchCriteriaTile({
  Icon,
  criteria,
  response,
  quote,
  setQuote,
}: {
  Icon: SvgJsxType;
  criteria?: string;
  response?: string;
  quote: string;
  setQuote: React.Dispatch<SetStateAction<string>>;
}) {
  return (
    <div className="p-3 flex flex-col gap-3 self-stretch bg-[#FDF5F3] rounded-xl">
      <div className="flex flex-col gap-[2px] self-stretch items-center">
        <div className="flex gap-2 self-stretch items-center">
          <Icon
            className="flex-shrink-0 stroke-[0.2]"
            height="16"
            width="16"
            stroke="#D23B15"
          />
          <h3 className="text-base font-semibold text-[#22234A]">{criteria}</h3>
        </div>
        <p className="text-sm font-medium text-[#4E4F6C]">{response}</p>
      </div>
      <div className="flex flex-col gap-2 self-stretch">
        <div className="flex self-stretch items-center justify-between">
          <InfoLabel
            className="font-semibold"
            info="We will display the above information to the client, paired with the context you provide in the below quote."
          >
            Add your quote
          </InfoLabel>
          <p className="text-xs font-medium text-[#727E94]">{`${quote.length}/${MAX_QUOTE_CHARACTERS}`}</p>
        </div>
        <Textarea
          value={quote}
          onChange={(_evt, { value }) => {
            if (value.length > MAX_QUOTE_CHARACTERS) {
              setQuote(value.substring(0, MAX_QUOTE_CHARACTERS));
            } else {
              setQuote(value);
            }
          }}
          resize="vertical"
        />
      </div>
    </div>
  );
}

export function NewOpportunityTile({
  opening,
  view = "dashboard",
}: {
  opening?: CompanyJobOpening;
  view?: "dashboard" | "opportunity";
}): JSX.Element {
  const navigate = useNavigate();

  if (!opening) {
    return (
      <div className="card flex-col gap-4 w-full">
        <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
          <div className="flex-1 flex flex-col gap-1">
            <Skeleton className="w-40 h-8">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
        <div className="flex gap-6 self-stretch">
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
        </div>
        <Skeleton className="w-full h-8">
          <SkeletonItem size={32} className="w-full" />
        </Skeleton>
      </div>
    );
  }

  return (
    <div className="card flex-col gap-4 w-full">
      <div className="flex gap-3 self-stretch items-center pb-4 border-bottom-line">
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-base font-semibold text-[#22234A]">
            {opening?.role}
          </p>
        </div>
        {view === "dashboard" ? (
          <Button
            appearance="secondary"
            shape="circular"
            onClick={() => navigate(`/opening/${opening.id}`)}
          >
            <p className="text-sm font-semibold text-[#4E4F6C]">See Details</p>
          </Button>
        ) : (
          <Button
            appearance="secondary"
            shape="circular"
            onClick={() => navigate(`/opening/${opening.id}/edit`)}
          >
            <p className="text-sm font-semibold text-[#4E4F6C]">Edit</p>
          </Button>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
        <OpportunityKeyPoint title="Scope" value={opening?.scope} />
        <OpportunityKeyPoint title="Pay" value={opening?.budget} />
        <OpportunityKeyPoint
          title="Desired Start Date"
          value={
            opening?.desiredStartDate &&
            dateStringToLocalDate(
              opening?.desiredStartDate,
            )?.toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })
          }
        />
      </div>
      <ProgressTracker
        opportunityId={opening?.id ?? ""}
        stages={COMPANY_STAGES}
        currentStage={
          opening?.status ? StatusToCompanyStageIndex[opening?.status] : 0
        }
      />
    </div>
  );
}

const OpportunityKeyPoint = ({
  title,
  value,
}: {
  title: "Scope" | "Pay" | "Desired Start Date";
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-1">
      <p className="text-sm font-medium text-[#727E94]">{title}:</p>
      <p className="text-sm font-semibold text-[#22234A]">{value}</p>
    </div>
  );
};

const ProgressTracker = ({
  opportunityId,
  stages,
  currentStage,
}: {
  opportunityId: string;
  stages: readonly string[];
  currentStage: number;
}) => {
  return (
    <div className="flex self-stretch items-stretch gap-10">
      {stages.map((stage, idx) => {
        const isPastStage = idx < currentStage;
        const isCurrentStage = idx === currentStage;

        let className = "";
        let ellipseProps = {};

        if (isPastStage) {
          className = "complete";
          ellipseProps = {
            stroke: "#19A594",
            fill: "#19A594",
          };
        } else if (isCurrentStage) {
          className = "active";
          ellipseProps = {
            stroke: "#19A594",
          };
        }

        return (
          <div
            key={`progress-stage-${opportunityId}-${idx}`}
            className={`progress-bar-item ${className} ${idx !== 0 && idx !== COMPANY_STAGES.length - 1 ? "flex-1" : ""}`}
          >
            <p>{stage}</p>
            <EllipseSVG className="progress-bar-circle" {...ellipseProps} />
          </div>
        );
      })}
    </div>
  );
};
