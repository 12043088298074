const dateStringToLocalDate = (dateString: string) => {
  const validate =
    /^([0-9]{4})-(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])$/gm.exec(
      dateString,
    ) ?? [];
  const [, year, month, day] = validate;

  // Date may be invalid
  const date = new Date(Number(year), Number(month) - 1, Number(day));

  // If date is invalid, return null
  if (Number.isNaN(date.getTime())) {
    return null;
  }

  return date;
};

export default dateStringToLocalDate;
