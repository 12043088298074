import { useAppSelector } from "../redux/hooks";
import { Link } from "@fluentui/react-components";

const Footer = () => {
  const user = useAppSelector((state) => state.auth.user);

  const userType = user?.userType;
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const isCompany =
    userType?.includes("Admin") || userType?.includes("Company");
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  return (
    <nav
      className={`${isCompany ? "flex" : "hidden"} sticky bottom-0 z-10 justify-between items-center bg-[#003C32]`}
    >
      <div className="w-full py-3 px-4 flex flex-col gap-2 justify-between items-center">
        <p className="text-normal font-medium text-white">
          If you&apos;d like to reach the Venturous team, please email{" "}
          <Link
            className="!text-[#ed5c38]"
            href="mailto:kevin@myventurous.com"
            target="_blank"
          >
            kevin@myventurous.com
          </Link>{" "}
          or schedule a time using this{" "}
          <Link
            className="!text-[#ed5c38]"
            href="https://calendly.com/kevin-venturous/30min"
            target="_blank"
          >
            link
          </Link>
          .
        </p>
      </div>
    </nav>
  );
};

export default Footer;
